import { css } from 'styled-components'
import { mediaQuery } from 'utils/style'

export const buttonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin: 0;
  font-size: var(--font-size-sm);
  font-weight: bold;
  line-height: 1;
  color: var(--color-text-primary);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: top;
  touch-action: manipulation;
  cursor: pointer;
  background-color: var(--color-surface-normal);
  border: none;
  border-radius: 4px;
  appearance: none;

  ${mediaQuery('940-max')} {
    &:hover {
      border-color: var(--color-border-strong);
    }
  }

  &[data-type='primary'] {
    border: none;
    background-color: var(--color-surface-success);
    color: var(--color-whiteAlpha-900);

    ${mediaQuery('940-max')} {
      &:hover {
        background-color: var(--color-surface-success-reaction);
      }
    }
  }
  &[data-type='secondary'] {
    color: var(--color-custom-accent);
    border: 1px solid var(--color-custom-accent);
    transition: all 200ms var(--easeInOutExpo);

    ${mediaQuery('768-max')} {
      &:hover {
        color: var(--color-custom-accent);
        border-color: var(--color-custom-accent-reaction);
      }
    }
  }
  &[data-type='danger'] {
    color: var(--color-whiteAlpha-900);
    background-color: var(--color-surface-danger);

    ${mediaQuery('768-max')} {
      transition: background-color 200ms var(--easeInOutExpo);
      &:hover {
        background-color: var(--color-surface-danger-reaction);
      }
    }
  }
  &[data-type='disabled'] {
    color: var(--color-text-secondary);
    cursor: not-allowed;
    background-color: var(--color-surface-disabled);
    border: none;
    transition: none;

    ${mediaQuery('768-max')} {
      &:hover {
        color: var(--color-text-secondary);
        background-color: var(--color-surface-disabled);
        border: none;
      }
    }
  }
  &[data-type='basic'] {
    color: var(--color-text-primary);
    border: 1px solid var(--color-border-default);

    ${mediaQuery('768-max')} {
      transition: border 200ms var(--easeInOutExpo);
      &:hover {
        border: 1px solid var(--color-grayAlpha-300);
      }
    }
  }
  &[data-type='none'] {
    color: var(--color-text-secondary);
    background-color: transparent;

    ${mediaQuery('768-max')} {
      transition: border 200ms var(--easeInOutExpo);
      &:hover {
        color: var(--color-text-primary);
      }
    }
  }

  &[data-size='small'] {
    font-size: var(--font-size-xs);
  }
  &[data-size='medium'] {
    font-size: var(--font-size-sm);
  }
  &[data-size='large'] {
    font-size: var(--font-size-base);
  }

  &[data-width='fit'] {
    width: 100%;
  }
  &[data-width='square'] {
    width: 32px;
  }

  &:disabled {
    color: var(--color-text-secondary);
    cursor: not-allowed;
    background-color: var(--color-surface-disabled);
    border: none;
    transition: none;

    ${mediaQuery('768-max')} {
      &:hover {
        color: var(--color-text-secondary);
        background-color: var(--color-surface-disabled);
        border: none;
      }
    }
  }
`
