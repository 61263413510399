import NextHead from 'next/head'

type Props = {
  title?: string
}

export const Head = (props: Props) => {
  const { title } = props

  const combiledTitle = title ? `${title} | note` : 'note'

  return (
    <NextHead>
      <title>{combiledTitle}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=10" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}
