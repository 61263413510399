import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useState } from 'react'

type CoachMark = 'onboarding_paywall'

export type CoachMarkState = {
  activeCoachMark: CoachMark | null
}

const initialCoachMarkState: CoachMarkState = {
  activeCoachMark: null
}

const CoachMarkContext = createContext<CoachMarkState>(initialCoachMarkState)
const SetCoachMarkContext = createContext<
  React.Dispatch<React.SetStateAction<CoachMarkState>>
>(() => {})

export const CoachMarkProvider = ({ children }: PropsWithChildren) => {
  const [coachMark, setCoachMark] = useState<CoachMarkState>(
    initialCoachMarkState
  )

  return (
    <CoachMarkContext.Provider value={coachMark}>
      <SetCoachMarkContext.Provider value={setCoachMark}>
        {children}
      </SetCoachMarkContext.Provider>
    </CoachMarkContext.Provider>
  )
}

export const useCoachMarkContext = (): CoachMarkState => {
  const context = useContext(CoachMarkContext)
  if (context === null) {
    throw new Error(
      'useCoachMarkContext must be used within a CoachMarkProvider'
    )
  }
  return context
}

export const useSetCoachMarkContext = (): React.Dispatch<
  React.SetStateAction<CoachMarkState>
> => {
  const context = useContext(SetCoachMarkContext)
  if (context === null) {
    throw new Error(
      'useSetCoachMarkContext must be used within a CoachMarkProvider'
    )
  }
  return context
}
