import { camelizeKeys as o, decamelizeKeys as d } from "humps";
import G from "redaxios";
export * from "redaxios";
import i from "swr";
import { fetchCurrentUserStateEditorPreferences as U, currentUserStateKeyMap as u, updateCurrentUserStateV3 as h, fetchCurrentUserStateForNotice as f, fetchCurrentUserStateOnboardingLikeReaction as E, fetchCurrentUserStateCouponNotice as O, fetchCurrentUserStateAssistants as D, fetchCurrentUserStateOnboardingPaywallLine as J, fetchCurrentUserStateDiscountCampaignErrors as x, fetchCurrentUserStateTwitterDiscountCampaignRetweet as N, fetchCurrentUserStateCreatorFes as $, fetchCurrentUserExperimentsV3 as R, searchHashtagsV3 as Y, getNoteCountsV3 as L, getOfficialEventsPostingSettingsV3 as T, getPersonalInformationStatusV3 as A, fetchMembershipPermissions as M, fetchOwnerMembershipPermissions as k, fetchOauthConnectionsV3 as K, postTwitterDiscountCampaignStatusV3 as Q, validateTwitterAccountForDiscountCampaignV3 as Z } from "@pocake/note-api-client";
const tt = process.env.API_BASE_URL, et = "/api", p = {
  baseURL: tt + et,
  withCredentials: !0,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
}, s = G.create(p), mt = async (t) => {
  const { key: e } = t, a = `/v2/notes/${e}/access_tokens`, r = await s.post(
    a,
    t
  );
  return o(r.data.data);
}, at = "/v2/attachments/upload", ft = async (t) => {
  const { file: e, fileName: a, noteKey: r } = t, n = new FormData();
  n.append("file", e), n.append("file_name", a), n.append("note_key", r);
  const c = await s.post(
    at,
    n
  );
  return o(c.data.data);
}, yt = async (t) => {
  const e = await s.get(
    `/v2/attachments/progress/${t}`
  );
  return o(e.data.data);
}, I = "/v2/contests", wt = async (t) => {
  const e = await s.get(I, { params: t });
  return o(e.data);
}, vt = (t) => {
  const { data: e, error: a } = i(I, (r) => s.get(r, { params: t }));
  return {
    data: e ? o(e.data.data) : void 0,
    error: a
  };
}, rt = "/v2/current_user", Ft = async () => {
  const t = await s.get(rt);
  return o(t.data.data);
}, _t = () => {
  const { data: t, error: e } = i(
    U.path(
      u.editorPreferences
    ),
    (a) => U.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.editorPreferences : void 0,
    error: e
  };
}, bt = async (t) => h.call(
  s,
  [u.editorPreferences],
  {
    editor_preferences: d(t)
  },
  p
), Ct = () => {
  const { data: t, error: e } = i(
    f.path(
      u.onboardingPublishModalHashtagSetting
    ),
    (a) => f.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.onboardingPublishModalHashtagSetting : void 0,
    error: e
  };
}, St = async (t) => h.call(
  s,
  [u.onboardingPublishModalHashtagSetting],
  {
    onboarding_publish_modal_hashtag_setting: {
      visibility: t
    }
  },
  p
), Pt = () => {
  const { data: t, error: e } = i(
    E.path(
      u.onboardingLikeReaction
    ),
    (a) => E.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.onboardingLikeReaction : void 0,
    error: e
  };
}, Ut = () => {
  const { data: t, error: e } = i(
    O.path(
      u.couponNotice
    ),
    (a) => O.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.couponNotice : void 0,
    error: e
  };
}, Et = async (t) => h.call(
  s,
  [u.couponNotice],
  {
    coupon_notice: {
      visibility: t
    }
  },
  p
), Ot = () => {
  const {
    data: t,
    error: e,
    mutate: a
  } = i(
    D.path(u.assistants),
    (r) => D.process(s, r),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.assistants : void 0,
    error: e,
    mutate: a
  };
}, Dt = async (t) => h.call(
  s,
  [u.assistants],
  {
    assistants: d(t)
  },
  p
), xt = () => {
  const { data: t, error: e } = i(
    f.path(
      u.onboardingPaywallLine
    ),
    (a) => J.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.onboardingPaywallLine : void 0,
    error: e
  };
}, Nt = async (t) => h.call(
  s,
  [u.onboardingPaywallLine],
  {
    onboarding_paywall_line: d({
      isDisplayed: t
    })
  },
  p
), $t = () => {
  const {
    data: t,
    error: e,
    mutate: a
  } = i(
    x.path(
      u.discountCampaignErrors
    ),
    (r) => x.process(s, r),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.discountCampaignErrors : void 0,
    error: e,
    mutate: a
  };
}, Rt = async (t) => h.call(
  s,
  [u.discountCampaignErrors],
  {
    discount_campaign_errors: d(t)
  },
  p
), Lt = () => {
  const { data: t, error: e } = i(
    N.path(
      u.twitterDiscountCampaignRetweet
    ),
    (a) => N.process(
      s,
      a
    ),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.twitterDiscountCampaignRetweet : void 0,
    error: e
  };
}, Tt = async (t) => h.call(
  s,
  [u.twitterDiscountCampaignRetweet],
  {
    twitter_discount_campaign_retweet: {
      visibility: t
    }
  },
  p
), At = () => {
  const { data: t, error: e } = i(
    $.path(u.creatorFes),
    (a) => $.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.creatorFes : void 0,
    error: e
  };
}, Mt = async (t) => h.call(
  s,
  [u.creatorFes],
  {
    creator_fes: d({
      editorPopupHiddenYears: t
    })
  },
  p
), kt = () => {
  const { data: t, error: e } = i(
    f.path(
      u.onboardingPublishModalRefundSetting
    ),
    (a) => f.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t ? t.onboardingPublishModalRefundSetting : void 0,
    error: e
  };
}, Kt = async (t) => h.call(
  s,
  [u.onboardingPublishModalRefundSetting],
  {
    onboarding_publish_modal_refund_setting: {
      visibility: t
    }
  },
  p
), st = "/v1/text_notes/draft_delete", It = async (t) => {
  const { id: e, onlyNoteDraft: a } = t, n = {
    params: d({
      id: e,
      onlyNoteDraft: a
    })
  }, c = await s.delete(
    st,
    n
  );
  if (c.data.error)
    throw new Error(c.data.error.message);
  return o(c.data.data);
}, nt = "/v1/text_notes/draft_save", zt = async (t) => {
  const {
    id: e,
    isTempSaved: a,
    ownerUrlname: r,
    body: n,
    bodyLength: c,
    name: l,
    stockPhotoImageId: g,
    separator: m,
    index: w,
    indexLocation: v,
    isLeadForm: F
  } = t, X = {
    body: n,
    bodyLength: c,
    name: l,
    stockPhotoImageId: g,
    separator: m,
    index: w,
    indexLocation: v,
    isLeadForm: F
  }, _ = {
    params: {
      id: e
    }
  };
  a && (_.params.isTempSaved = a), r && (_.params.ownerUrlname = r);
  const b = await s.post(
    nt,
    d(X),
    d(_)
  );
  if (b.data.error)
    throw new Error(b.data.error.message);
  return o(b.data.data);
}, C = {
  embed: "/v1/embed",
  embedByExternalAPI: "/v2/embed_by_external_api",
  embedByExternalAPICheckType: "/v2/embed_by_external_api/check_type"
}, Vt = async (t) => {
  const e = await s.post(
    C.embed,
    t
  );
  return o(e.data.data);
}, ot = async (t) => {
  const e = await s.get(
    C.embedByExternalAPI,
    {
      params: d(t)
    }
  );
  return o(e.data.data);
}, Ht = async (t) => {
  const e = await s.get(C.embedByExternalAPICheckType, {
    params: d(t)
  });
  return o(
    e.data.data
  );
}, Bt = (t) => {
  const { data: e, error: a } = i(
    R.path(t, void 0),
    () => R.call(
      s,
      [t, void 0],
      void 0,
      p
    )
  );
  return {
    data: e == null ? void 0 : e.experiments,
    error: a
  };
}, z = {
  upload: "/v1/image_upload/note_eyecatch",
  delete: "/v1/image_upload/note_eyecatch/delete"
}, qt = async (t) => {
  const { note_id: e, file: a, canvaId: r, adobeExpressId: n } = t, c = new FormData();
  c.append("note_id", String(e)), c.append("file", a), c.append("width", String(1920)), c.append("height", String(1005)), r && c.append("canva_id", r), n && c.append("adobe_express_id", n);
  const l = await s.post(
    z.upload,
    c
  );
  if (l.data.error)
    throw new Error(l.data.error.code);
  return o(l.data.data);
}, jt = async (t) => {
  const e = {
    ...t
  }, a = await s.put(
    z.delete,
    e
  );
  if (a.data.error)
    throw new Error(a.data.error.code);
  return o(a.data.data);
}, Wt = async (t) => {
  const e = process.env.fileUploadPresignedPostURL;
  if (e === void 0) {
    console.error("file upload URL is not found. [fileUploadPresignedPostURL]");
    return;
  }
  const a = {
    body: JSON.stringify({ filename: t.name }),
    credentials: "omit",
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST"
  }, r = await ct(
    e,
    a,
    (c) => {
      console.log(c);
    }
  );
  if (r === void 0)
    return;
  const n = await it(t, r.data, (c) => {
    console.log(c);
  });
  if (n != null && n.ok)
    return {
      status: "success",
      data: {
        url: r.data.url,
        path: r.data.path
      }
    };
  throw new Error("error");
}, ct = async (t, e, a) => {
  try {
    const r = await fetch(t, e);
    if (r.ok === !1) {
      a(new Error(r.statusText));
      return;
    }
    return r.json();
  } catch (r) {
    a(r);
    return;
  }
}, it = async (t, e, a) => {
  try {
    const r = e.action, n = new FormData();
    return e.post["X-Amz-Algorithm"] = "AWS4-HMAC-SHA256", Object.keys(e.post).forEach((c) => {
      n.append(c, e.post[c]);
    }), n.append("file", t), await fetch(r, {
      body: n,
      credentials: "omit",
      headers: {},
      method: "POST"
    });
  } catch (r) {
    return a(r);
  }
}, S = {
  suggested: "/v1/hashtags/suggested/TextNote",
  search: "/v3/hashtags/search",
  searchElasticsearch: "/v3/search/hashtags"
}, Xt = async () => {
  const t = await s.get(
    S.suggested
  );
  if (t.data.error)
    throw new Error(t.data.error.message);
  return o(t.data.data);
}, Gt = () => {
  const { data: t, error: e } = i(S.suggested, s);
  return {
    data: t ? o(t.data.data) : void 0,
    error: e
  };
}, Jt = (t) => {
  const { data: e, error: a } = i(
    t ? [S.searchElasticsearch, t] : null,
    ([r, n]) => Y.call(s, [n, !0], void 0, p)
  );
  return {
    data: e,
    error: a
  };
}, dt = "/v1/image_upload/text_note_picture", Yt = async (t) => {
  const { file: e, noteId: a } = t, r = new FormData();
  r.append("file", e), r.append("note_id", String(a));
  const n = await s.post(dt, r);
  if (n.data.error)
    throw new Error(n.data.error.message);
  return o(n.data.data);
}, Qt = async (t) => {
  const { key: e } = t, a = await s.get(
    `/v3/notes/${e}/latest_draft`
  );
  return o(a.data.data);
}, Zt = (t) => {
  const { key: e } = t, { data: a, error: r } = i(`/v3/notes/${e}/latest_draft`, (n) => s.get(n));
  return {
    data: a ? o(a.data.data) : void 0,
    error: r
  };
}, te = async (t) => {
  const { key: e, lastUpdatedAt: a } = t, r = {
    params: d({
      lastUpdatedAt: a
    })
  }, n = await s.get(`/v3/notes/${e}/latest_draft/version_verification`, r);
  return o(
    n.data.data
  );
}, ee = (t) => {
  const { key: e, lastUpdatedAt: a } = t, r = {
    params: d({
      lastUpdatedAt: a
    })
  }, { data: n, error: c } = i(
    `/v3/notes/${e}/latest_draft/version_verification`,
    (l) => s.get(l, r)
  );
  return {
    data: n ? o(
      n.data.data
    ) : void 0,
    error: c
  };
}, P = "/v1/my/magazines", ae = async (t) => {
  const e = await s.get(
    P,
    {
      params: d(t)
    }
  );
  if (e.data.error)
    throw new Error(e.data.error.code);
  return o(e.data.data);
}, re = async (t) => {
  const e = await s.post(
    P,
    t
  );
  if (e.data.error)
    throw new Error(e.data.error.code);
  return o(e.data.data);
}, se = (t) => {
  const { data: e, error: a } = i(
    t.noteKey ? [P, t.noteKey] : null,
    ([r]) => s.get(r, {
      params: d(t)
    })
  );
  return {
    data: e ? o(e.data.data) : void 0,
    error: a
  };
}, ne = async (t) => {
  const { key: e, urlname: a } = t, r = await s.post(
    `/v3/members/owner_notes/${e}/move`,
    null,
    { params: { urlname: a } }
  );
  return o(r.data.data);
}, oe = () => {
  const {
    data: t,
    error: e,
    isLoading: a
  } = i(
    L.path,
    (r) => L.process(s, r),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t || void 0,
    error: e,
    isLoading: a
  };
}, ce = async (t) => {
  const { noteKey: e } = t, a = await s.post(
    `/v1/notes/${e}/notify_update`
  );
  if (a.data.error)
    throw new Error(a.data.error.code);
  return o(a.data.data);
}, ie = () => {
  const { data: t, error: e } = i(
    T.path(),
    (a) => T.process(s, a),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t,
    error: e
  };
}, V = "/v3/members/owner_notes/magazines", de = async (t) => {
  const { urlname: e, includedNoteKey: a } = t, r = await s.get(
    V,
    { params: d({ urlname: e, includedNoteKey: a }) }
  );
  return o(r.data.data);
}, ue = (t) => {
  const { urlname: e, includedNoteKey: a } = t, { data: r, error: n } = i(
    e ? [V, e, a] : null,
    ([c]) => s.get(c, {
      params: d({ urlname: e, includedNoteKey: a })
    })
  );
  return {
    data: r ? o(r.data.data) : void 0,
    error: n
  };
}, pe = async (t) => {
  const { urlname: e, page: a } = t, r = await s.get(
    "/v3/members/owner_notes",
    { params: { urlname: e, page: a } }
  );
  return o(r.data.data);
}, le = async (t) => {
  const { urlname: e } = t, a = await s.get(
    "/v3/members/owner_notes/archives",
    { params: { urlname: e } }
  );
  return o(a.data.data);
}, H = "/v3/members/owners", ge = async (t) => {
  const e = await s.get(
    H,
    t ? { params: d(t) } : void 0
  );
  return o(e.data.data);
}, he = (t) => {
  const { data: e, error: a } = i(
    H,
    (r) => s.get(r, t ? { params: d(t) } : void 0),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: e ? o(e.data.data) : void 0,
    error: a
  };
}, B = "/v3/members/owners/settings", me = async (t) => {
  const { urlname: e } = t, a = await s.get(
    B,
    { params: { urlname: e } }
  );
  return o(a.data.data);
}, fe = (t) => {
  const { urlname: e } = t, { data: a, error: r } = i(
    e ? [B, e] : null,
    ([n]) => s.get(n, {
      params: { urlname: e }
    }),
    {
      shouldRetryOnError: !1
    }
  );
  return {
    data: a ? o(a.data.data) : void 0,
    error: r
  };
}, ye = (t) => {
  const e = async (l) => await A.process(
    s,
    l,
    t
  ), { data: a, isLoading: r, error: n, mutate: c } = i(
    A.path(),
    e,
    {
      revalidateOnFocus: !1,
      suspense: !0
    }
  );
  return {
    data: a,
    isLoading: r,
    error: n,
    mutate: c
  };
}, q = "/v1/reserved_note", we = async (t) => {
  const { noteKey: e } = t, a = await s.get(
    q + "/" + e
  );
  if (a.data.error)
    throw new Error(a.data.error.code);
  return o(a.data.data);
}, ve = (t) => {
  const { noteKey: e } = t, { data: a, error: r } = i(q + "/" + e, (n) => s.get(n));
  return a != null && a.data.error ? {
    data: void 0,
    error: a.data.error
  } : {
    data: a ? o(a.data.data) : void 0,
    error: r
  };
}, j = "/v2/stock_photo_images", Fe = async (t) => {
  const e = await s.get(j, {
    params: d(t)
  });
  return o(e.data);
}, ut = (t) => (e, a) => [
  j,
  e + 1,
  t.query,
  t.size,
  t.publicDomainOnly
], _e = (t) => (e) => {
  const { data: a, error: r, size: n, setSize: c } = t(
    ut(e),
    ([g, m, w, v, F]) => s.get(g, {
      params: d({
        page: m,
        query: w,
        size: v,
        publicDomainOnly: F,
        noteId: e.noteId
      })
    })
  );
  return {
    data: a == null ? void 0 : a.map(
      (g) => o(g.data.data)
    ).reduce((g, m) => ({
      contents: [...g.contents, ...m.contents],
      isLastPage: m.isLastPage,
      totalCount: m.totalCount
    })),
    error: r,
    size: n,
    setSize: c
  };
}, y = {
  notes: "/v3/notes",
  textNotes: "/v1/text_notes"
}, be = async (t) => {
  const { noteKey: e, draft: a = !0, reedit: r = !1 } = t, n = {
    draft: a,
    draft_reedit: r,
    ts: Date.now()
  }, c = await s.get(
    `${y.notes}/${e}`,
    {
      params: n
    }
  );
  return o(c.data.data);
}, Ce = (t) => {
  const { noteKey: e, draft: a = !0, reedit: r = !1 } = t, n = {
    draft: a,
    draft_reedit: r,
    ts: Date.now()
  }, { data: c, error: l } = i(
    e ? `${y.notes}/${e}` : null,
    (g) => s.get(g, { params: n }),
    {
      revalidateOnFocus: !1,
      shouldRetryOnError: !1
    }
  );
  return c != null && c.data.error ? {
    data: void 0,
    error: c.data.error
  } : {
    data: c ? o(c.data.data) : void 0,
    error: l
  };
}, Se = async (t) => {
  const e = d({
    ...t
  }), a = await s.post(
    y.textNotes,
    e
  );
  return o(a.data.data);
}, Pe = async (t, e) => {
  const a = d({
    ...e
  }), r = await s.put(
    `${y.textNotes}/${t}`,
    a
  );
  if (r.data.error)
    throw new Error(r.data.error.message);
  return o(r.data.data);
}, W = "/v3/users/user_features", Ue = async () => {
  const t = await s.get(W);
  return o(t.data);
}, Ee = () => {
  const { data: t, error: e } = i(W, (a) => s.get(a));
  return {
    data: t ? o(t.data.data) : void 0,
    error: e
  };
}, Oe = (t) => {
  const { noteKey: e } = t;
  return i(
    e ? M.path() : null,
    (a) => M.process(s, a, { noteKey: e })
  );
}, De = (t) => {
  const { noteKey: e, urlname: a } = t;
  return i(
    e && a ? k.path() : null,
    (r) => k.process(s, r, { noteKey: e, urlname: a })
  );
}, xe = () => {
  const {
    data: t,
    error: e,
    mutate: a
  } = i(
    K.path(),
    (r) => K.process(s, r),
    {
      revalidateOnFocus: !1
    }
  );
  return {
    data: t,
    error: e,
    mutate: a
  };
}, Ne = async (t) => {
  const { noteKey: e } = t;
  let a = [];
  try {
    a = await Q.call(
      s,
      void 0,
      { noteKey: e },
      p
    );
  } catch (n) {
    const c = n.data.error.message;
    if (typeof c != "string")
      throw n;
    return {
      discountCampaigns: void 0,
      errorMessage: c
    };
  }
  const r = a.find(
    ({ kind: n }) => n === "twitter_retweet"
  );
  if (r != null && r.twitterStatus)
    try {
      await ot({
        url: r.twitterStatus.url,
        service: "twitter",
        embeddableKey: r.twitterStatus.key,
        embeddableType: "TwitterStatus"
      });
    } catch {
    }
  return {
    discountCampaigns: a,
    errorMessage: void 0
  };
}, $e = async () => {
  try {
    return await Z.call(
      s,
      void 0,
      void 0,
      p
    ), "";
  } catch (t) {
    const e = t.data.error.message;
    if (typeof e != "string")
      throw t;
    return e;
  }
};
export {
  de as GetOwnerMagazines,
  s as axios,
  p as axiosConfig,
  It as deleteDraftDelete,
  jt as deleteNoteEyecatch,
  Wt as fileUpload,
  yt as getAttachmentsProgress,
  wt as getContests,
  Ft as getCurrentUser,
  Ht as getEmbedByExternalApiCheckType,
  Xt as getHashtagsSuggested,
  Qt as getLatestDraft,
  te as getLatestDraftVersionVerification,
  ae as getMyMagazines,
  pe as getOwnerNotes,
  le as getOwnerNotesArchives,
  me as getOwnerSettings,
  ge as getOwners,
  we as getReservedNote,
  Fe as getStockPhotoImages,
  be as getTextNote,
  Ue as getUserFeatures,
  mt as postAccessTokens,
  ft as postAttachmentsUpload,
  zt as postDraftSave,
  Vt as postEmbed,
  ot as postEmbedByExternalApi,
  ne as postMoveDraftToOwner,
  re as postMyMagazines,
  qt as postNoteEyecatch,
  ce as postNotifyUpdate,
  Ne as postReservedTwitterStatus,
  Se as postTextNote,
  Yt as postTextNotePicture,
  Pe as putTextNote,
  Dt as updateCurrentUserStateForAssistants,
  Et as updateCurrentUserStateForCouponNotice,
  Mt as updateCurrentUserStateForCreatorFes,
  Rt as updateCurrentUserStateForDiscountCampaignErrors,
  St as updateCurrentUserStateForNotice,
  Nt as updateCurrentUserStateForOnboardingPaywallLine,
  Kt as updateCurrentUserStateForRefundSettingNotice,
  Tt as updateCurrentUserStateForTwitterDiscountCampaignRetweet,
  bt as updateEditorPreferences,
  vt as useFetchContests,
  Bt as useFetchCurrentUserExperiments,
  Ot as useFetchCurrentUserStateForAssistants,
  Ut as useFetchCurrentUserStateForCouponNotice,
  At as useFetchCurrentUserStateForCreatorFes,
  $t as useFetchCurrentUserStateForDiscountCampaignErrors,
  Ct as useFetchCurrentUserStateForNotice,
  Pt as useFetchCurrentUserStateForOnboardingLikeReaction,
  xt as useFetchCurrentUserStateForOnboardingPaywallLine,
  kt as useFetchCurrentUserStateForRefundSettingNotice,
  Lt as useFetchCurrentUserStateForTwitterDiscountCampaignRetweet,
  _t as useFetchEditorPreferences,
  Gt as useFetchHashtagsSuggested,
  Zt as useFetchLatestDraft,
  ee as useFetchLatestDraftVersionVerification,
  Oe as useFetchMembershipPermissions,
  se as useFetchMyMagazines,
  oe as useFetchNoteCount,
  xe as useFetchOauthConnections,
  ie as useFetchOfficialEventsPostingSettings,
  ue as useFetchOwnerMagazines,
  De as useFetchOwnerMembershipPermissions,
  fe as useFetchOwnerSettings,
  he as useFetchOwners,
  ve as useFetchReservedNote,
  Jt as useFetchSearchHashtags,
  _e as useFetchStockPhotoImages,
  Ce as useFetchTextNote,
  Ee as useFetchUserFeatures,
  ye as usePersonalInformationStatus,
  $e as validateTwitterAccountForDiscountCampaign
};
