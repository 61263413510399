import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Modal from 'react-modal'
import { createGlobalStyle, css, keyframes } from 'styled-components'
import reset from 'styled-reset'
import variables from '@pocake/note-common-styles/json/css-variables.json'
import {
  TrackingProvider,
  useInitTrackerForPagesRouter
} from '@pocake/note-custom-hooks'
import { Provider as NoteUIProvider } from '@pocake/noteui'
import { firefox, isIE, polyfillRequestIdleCallback } from '@note-editor/utils'
import { Head } from 'components/molecules/Head'
import { FlashMessage } from 'components/organisms/FlashMessage'
import { OnboardingMessage } from 'components/organisms/OnboardingMessage'
import { AssistantsProvider } from 'contexts/AssistantsContext'
import { AuthStateProvider, useAuthState } from 'contexts/AuthContext'
import { CoachMarkProvider } from 'contexts/CoachMarkContext'
import { FlashMessageProvider } from 'contexts/FlashMessageContext'
import { IndexSettingProvider } from 'contexts/IndexSettingContext'
import { OnboardingProvider } from 'contexts/OnboardingContext'
import { WebViewStateProvider } from 'contexts/WebViewContext'
import { buttonStyle } from 'styles/buttonStyle'
import '@pocake/note-common-styles/scss/partials/textnote/_style.scss'
import { mediaQuery } from 'utils/style'
import '../styles/globals.css'

import 'tailwindcss/tailwind.css'

Modal.setAppElement('#__next')

polyfillRequestIdleCallback()

const loadingAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
`

const outline = css`
  box-shadow: none;
  outline: 4px solid var(--color-border-success);
  outline-offset: -4px;
`

const ProseMirrorDefault = createGlobalStyle`
  .ProseMirror {
    position: relative;
  }

  .ProseMirror {
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: break-spaces;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0;
  }

  .ProseMirror figure[embedded-service] {
    white-space: normal;
  }

  .ProseMirror pre {
    white-space: pre-wrap;
  }
`

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${variables['css-variables']}

  * {
    box-sizing: border-box;
  }
  html {
    height: -webkit-fill-available;
    scroll-behavior: smooth;
  }
  html, body {
    height: 100%;
  }
  body {
    min-height: -webkit-fill-available;
    font-family: 
      -apple-system,
      BlinkMacSystemFont,
      Helvetica Neue,
      Segoe UI,
      Hiragino Kaku Gothic ProN,
      Hiragino Sans,
      ヒラギノ角ゴ ProN W3,
      Arial,
      メイリオ,
      Meiryo,
      sans-serif;
    color: var(--color-text-primary);
    -webkit-font-smoothing: antialiased;
    background: var(--color-background-primary);
    line-height: 1.5;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  button {
    appearance: none;
    background-color: initial;
    cursor: pointer;
    margin: 0;
    font-size: var(--font-size-sm);
    color: var(--color-text-primary);
  }

  .ProseMirror {
    overflow: visible;
    padding-top: 36px;

    ${mediaQuery('min-480')} {
      padding-top: 30px;
    }

    & > *:first-child {
      margin-top: 0;
    }
  }

  .ProseMirror a {
    pointer-events: none;
  }
  .ProseMirror figure > a {
    pointer-events: auto;
  }

  .ProseMirror [contenteditable="false"] a {
    display: block;
  }

  .ProseMirror iframe,
  .ProseMirror .iframely-embed {
    pointer-events: none;
  }

  .ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: relative;
    height: 20px;
  }

  .ProseMirror-gapcursor:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    height: 20px;
    border-left: 1px solid var(--color-gray-900);
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  }

  @keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }

  .ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }

  .ProseMirror-focused {
    & > .paragraph,
    & > .heading,
    & > .blockquote,
    & > .codeBlock,
    & > .bulletList,
    & > .orderedList {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -16px;
        width: 3px;
        height: 100%;
        background-color: var(--color-surface-disabled);
      }
    }
  }

  .ProseMirror-selectednode {
    overflow: initial;

    & img::selection {
      background-color: transparent;
    }

    /* &.paragraph,
    &.heading,
    &.blockquote,
    &.codeBlock,
    &.attachment {
      ${outline}
    } */
    &.embed > span > * {
      ${outline}
    }
    &.embed[embedded-service="note"] {
      ${outline}
      outline-offset: 0;
    }
    &.image > img,
    &.image > a > img {
      ${outline}
    }
    &.tableOfContents {
      display: block;
      ${outline}
      outline-offset: 0;
    }
  }

  .ProseMirror-hideselection *::selection {
    background: transparent;
  }
  .ProseMirror-hideselection *::-moz-selection {
    background: transparent;
  }
  .ProseMirror-hideselection {
    caret-color: transparent;
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    border: 1px dashed var(--color-border-default);

    & div {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background-color: var(--color-gray-900);
      border-radius: 8px;
      opacity: 0.3;

      &:nth-child(1) {
        animation: ${loadingAnimation} 2000ms ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: ${loadingAnimation} 2000ms ease-in-out infinite 666ms;
      }
      &:nth-child(3) {
        animation: ${loadingAnimation} 2000ms ease-in-out infinite 1300ms;
      }
    }
  }

  .ProseMirror[data-placeholder] {
    &::before {
      position: absolute;
      top: 36px;
      left: 0;
      font-size: var(--font-size-lg);
      line-height: 36px;
      content: attr(data-placeholder);
      color: var(--color-text-placeholder);
      pointer-events: none;

      ${mediaQuery('min-480')} {
        top: 30px;
        left: 16px;
      }
    }
  }

  .highlight {
    border-radius: 1px;
    /* stylelint-disable-next-line */
    background: rgba(35, 131, 226, 0.28);
    /* stylelint-disable-next-line */
    box-shadow: 0 0 0 3px rgba(35, 131, 226, 0.28);
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & div {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background-color: var(--color-gray-900);
      border-radius: 8px;
      opacity: 0.3;

      &:nth-child(1) {
        animation: ${loadingAnimation} 2000ms ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: ${loadingAnimation} 2000ms ease-in-out infinite 666ms;
      }
      &:nth-child(3) {
        animation: ${loadingAnimation} 2000ms ease-in-out infinite 1300ms;
      }
    }
  }

  .paywall-setting {
    & > * {
      pointer-events: none;
      user-select: none;
    }

    & > .paywall-line ~ * {
      opacity: 0.2;
    }
  }

  .paywall {
    height: 1px;
    background-color: var(--color-grayAlpha-300);
    overflow: visible;
    text-align: center;

    &::after {
      content: '有料ライン';
      position: relative;
      top: -7px;
      padding: 4px 8px;
      border: 1px solid var(--color-grayAlpha-300);
      border-radius: 14px;
      background-color: var(--color-surface-normal);
      font-size: var(--font-size-sm);
    }
  }

  .a-button {
    ${buttonStyle}
  }

  .a-button__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .a-icon--fileDownload {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 1;
    background-image: url('/icons/fileDownload.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: scale(0.9);
    transition: all 200ms var(--easeInOut);
  }

  .ReactModal__Content--after-open{
      opacity: 1;
      transform: scale(1.0);
  }

  .ReactModal__Content--before-close{
      opacity: 0;
      transform: scale(0.9);
  }

  .ProseMirror > figure > img {
    &[src^='data:image'] {
      filter: opacity(0.25);
    }
    &:not([src^='data:image']) {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20100%20100%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22a%22%3E%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22%23f7f9f9%22%2F%3E%3Cstop%20offset%3D%2233%25%22%20stop-color%3D%22%23f7f9f9%22%2F%3E%3Cstop%20offset%3D%2250%25%22%20stop-color%3D%22%23fff%22%2F%3E%3Cstop%20offset%3D%2267%25%22%20stop-color%3D%22%23f7f9f9%22%2F%3E%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22%23f7f9f9%22%2F%3E%3CanimateTransform%20attributeName%3D%22gradientTransform%22%20type%3D%22translate%22%20from%3D%22-1%200%22%20to%3D%221%200%22%20begin%3D%220s%22%20dur%3D%221.5s%22%20repeatCount%3D%22indefinite%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22rect%22%20fill%3D%22url(%23a)%22%20d%3D%22M-100-100h300v300h-300z%22%2F%3E%3C%2Fsvg%3E');
      background-size: cover;
      background-position: center center;
    }
    &[src$='.png'] {
      background-image: none;
    }
  }
`

export default function App(props: AppProps) {
  const { Component, pageProps } = props

  if (isIE()) {
    window.location.href = 'https://note.com'
  }

  return (
    <>
      <Head />
      {firefox() && <ProseMirrorDefault />}
      <GlobalStyle />
      <NoteUIProvider>
        <AuthStateProvider>
          <WebViewStateProvider>
            <IndexSettingProvider>
              <FlashMessageProvider>
                <CoachMarkProvider>
                  <OnboardingProvider>
                    <AssistantsProvider>
                      <TrackingProvider>
                        <>
                          <Component {...pageProps} />
                          <FlashMessage />
                          <OnboardingMessage />
                          <Tracker />
                        </>
                      </TrackingProvider>
                    </AssistantsProvider>
                  </OnboardingProvider>
                </CoachMarkProvider>
              </FlashMessageProvider>
            </IndexSettingProvider>
          </WebViewStateProvider>
        </AuthStateProvider>
      </NoteUIProvider>
    </>
  )
}

const Tracker = () => {
  const { currentUser } = useAuthState()
  const router = useRouter()
  useInitTrackerForPagesRouter({
    currentUser: currentUser as any,
    isNotLoggedIn: false,
    router,
    option: {
      env: {
        development: process.env.NODE_ENV === 'development',
        beta: process.env.NEXT_ENV?.includes('beta') ?? false,
        rc: process.env.NEXT_ENV?.includes('rc') ?? false,
        production: process.env.NODE_ENV === 'production',
        test: process.env.NODE_ENV === 'test'
      },
      apiBaseURL: process.env.API_BASE_URL + '/api',
      trackingEndpointFluentd: process.env.trackingEndpointFluentd ?? '',
      trackingEndpoint: process.env.trackingEndpoint ?? '',
      trackingEndpointProtobuf: process.env.trackingEndpointProtobuf ?? ''
    }
  })
  return null
}
