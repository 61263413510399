import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useFlashMessageContext } from 'contexts/FlashMessageContext'

type Visibility = 'visible' | 'leaving' | 'hidden'

export const FlashMessage = () => {
  const { isVisible, status, text } = useFlashMessageContext()

  const [isLeaving, setIsLeaving] = useState(false)

  useEffect(() => {
    if (isVisible === false) {
      setIsLeaving(true)

      setTimeout(() => {
        setIsLeaving(false)
      }, 1000)
    }
  }, [isVisible])

  const visibility: Visibility = useMemo(() => {
    if (isVisible) {
      return 'visible'
    }

    if (isVisible === false && isLeaving === true) {
      return 'leaving'
    }

    return 'hidden'
  }, [isVisible, isLeaving])

  return (
    <Container data-visibility={visibility}>
      <Inner>
        <Label data-status={status}>{text}</Label>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
  transition: transform 200ms var(--easeInOut);
  transform: translate3d(0, -100%, 0);
  pointer-events: none;

  &[data-visibility='visible'] {
    transform: translate3d(0, 0, 0);
  }
  &[data-visibility='leaving'] {
    transform: translate3d(0, -100%, 0);
  }
  &[data-visibility='hidden'] {
    transform: translate3d(0, -100%, 0);
  }
`

const Inner = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  pointer-events: auto;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    content: '';
    background-color: var(--color-surface-normal);
  }
`

const Label = styled.div`
  position: relative;
  z-index: 2;
  padding: 8px 16px;
  font-weight: bold;

  &[data-status='success'] {
    color: var(--color-text-success);
    background-color: var(--color-surface-successSubdued);
  }
  &[data-status='warning'] {
    color: var(--color-text-caution);
    background-color: var(--color-surface-cautionSubdued);
  }
  &[data-status='error'] {
    color: var(--color-text-danger);
    background-color: var(--color-surface-dangerSubdued);
  }
  &[data-status='info'] {
    color: var(--color-text-primary);
    background-color: var(--color-background-secondary);
  }
`
