import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useState } from 'react'

export type FlashMessageStatus = 'success' | 'warning' | 'error' | 'info'

export type FlashMessageState = {
  isVisible: boolean
  status: FlashMessageStatus
  text: string
}

const initialFlashMessageState: FlashMessageState = {
  isVisible: false,
  status: 'info',
  text: ''
}

const FlashMessageContext = createContext<FlashMessageState>(
  initialFlashMessageState
)
const SetFlashMessageContext = createContext<
  React.Dispatch<React.SetStateAction<FlashMessageState>>
>(() => {})

export const FlashMessageProvider = ({ children }: PropsWithChildren) => {
  const [flashMessage, setFlashMessage] = useState<FlashMessageState>(
    initialFlashMessageState
  )

  return (
    <FlashMessageContext.Provider value={flashMessage}>
      <SetFlashMessageContext.Provider value={setFlashMessage}>
        {children}
      </SetFlashMessageContext.Provider>
    </FlashMessageContext.Provider>
  )
}

export const useFlashMessageContext = (): FlashMessageState => {
  const context = useContext(FlashMessageContext)
  if (context === null) {
    throw new Error(
      'useFlashMessageContext must be used within a FlashMessageProvider'
    )
  }
  return context
}

export const useSetFlashMessageContext = () => {
  const setFlashMessage = useContext(SetFlashMessageContext)
  if (setFlashMessage === null) {
    throw new Error(
      'useSetFlashMessageContext must be used within a FlashMessageProvider'
    )
  }

  return (nextState: Pick<FlashMessageState, 'status' | 'text'>) => {
    const duration = 3000

    setFlashMessage((prev) => ({
      ...prev,
      ...nextState,
      isVisible: true
    }))

    setTimeout(() => {
      setFlashMessage((prev) => ({
        ...prev,
        isVisible: false
      }))
    }, duration)
  }
}
