import { createContext, useContext, useState, useEffect } from 'react'
import type { ReactNode, Dispatch, SetStateAction } from 'react'
import { getCurrentUser } from '@note-editor/api'
import type { CurrentUser } from '@note-editor/types'

export type AuthState = {
  currentUser: CurrentUser | null
}

const initialState: AuthState = {
  currentUser: null
}

const AuthStateContext = createContext<AuthState>(initialState)

const SetAuthStateContext = createContext<Dispatch<SetStateAction<AuthState>>>(
  () => {}
)

export function useAuthState() {
  return useContext(AuthStateContext)
}
export function useSetAuthState() {
  return useContext(SetAuthStateContext)
}

export function AuthStateProvider(props: {
  initialState?: AuthState
  children: ReactNode
}) {
  const [state, setState] = useState<AuthState>(
    props.initialState ?? initialState
  )

  useEffect(() => {
    ;(async () => {
      try {
        const response = await getCurrentUser()

        setState((state) => ({
          ...state,
          currentUser: response
        }))
      } catch (error) {
        console.log(error)

        const redirectPath = encodeURIComponent(window.location.href)
        window.location.href = `${process.env.NOTE_FRONT_URL}/login?redirectPath=${redirectPath}`
      }
    })()
  }, [])

  return (
    <AuthStateContext.Provider value={state}>
      <SetAuthStateContext.Provider value={setState}>
        {props.children}
      </SetAuthStateContext.Provider>
    </AuthStateContext.Provider>
  )
}
