import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useState } from 'react'

type IndexSettingState = {
  isIndexEnabled: boolean
}

const initialIndexSettingState: IndexSettingState = {
  isIndexEnabled: false
}

const IndexSettingContext = createContext<IndexSettingState>(
  initialIndexSettingState
)

const SetIndexSettingContext = createContext<
  React.Dispatch<React.SetStateAction<IndexSettingState>>
>(() => {})

export const IndexSettingProvider = ({ children }: PropsWithChildren) => {
  const [indexSetting, setIndexSetting] = useState<IndexSettingState>(
    initialIndexSettingState
  )

  return (
    <IndexSettingContext.Provider value={indexSetting}>
      <SetIndexSettingContext.Provider value={setIndexSetting}>
        {children}
      </SetIndexSettingContext.Provider>
    </IndexSettingContext.Provider>
  )
}

export const useIndexSettingContext = (): IndexSettingState => {
  const context = useContext(IndexSettingContext)
  if (context === null) {
    throw new Error(
      'useIndexSettingContext must be used within a IndexSettingProvider'
    )
  }
  return context
}

export const useSetIndexSettingContext = (): React.Dispatch<
  React.SetStateAction<IndexSettingState>
> => {
  const context = useContext(SetIndexSettingContext)
  if (context === null) {
    throw new Error(
      'useSetIndexSettingContext must be used within a IndexSettingProvider'
    )
  }
  return context
}
