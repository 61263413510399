import { useRouter } from 'next/router'
import { createContext, useContext, useState, useEffect } from 'react'
import type { ReactNode, Dispatch, SetStateAction } from 'react'

type MobileApp = 'ios' | 'android'

export type WebViewState = {
  app: MobileApp | null
  isAndroid: boolean
  reedit: boolean
  hashtag: string | null
}

const initialState: WebViewState = {
  app: null,
  isAndroid: false,
  reedit: false,
  hashtag: null
}

const WebViewStateContext = createContext<WebViewState>(initialState)

const SetWebViewStateContext = createContext<
  Dispatch<SetStateAction<WebViewState>>
>(() => {})

export const useWebViewState = () => {
  return useContext(WebViewStateContext)
}
export const useSetWebViewState = () => {
  return useContext(SetWebViewStateContext)
}

export const WebViewStateProvider = (props: {
  initialState?: WebViewState
  children: ReactNode
}) => {
  const [state, setState] = useState<WebViewState>(
    props.initialState ?? initialState
  )

  const router = useRouter()

  useEffect(() => {
    const { app, reedit, hashtag } = router.query

    if (app && typeof app === 'string') {
      setState((state) => ({
        ...state,
        app: (app as MobileApp) ?? null,
        isAndroid: app === 'android',
        reedit: reedit === 'true',
        hashtag: (hashtag as string) ?? null
      }))
    }
  }, [router.query])

  return (
    <WebViewStateContext.Provider value={state}>
      <SetWebViewStateContext.Provider value={setState}>
        {props.children}
      </SetWebViewStateContext.Provider>
    </WebViewStateContext.Provider>
  )
}
