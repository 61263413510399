import type { PropsWithChildren, ReactNode } from 'react'
import React, { createContext, useContext, useState } from 'react'

export type OnboardingState = {
  contents: ReactNode | null
  closeCallback: (() => void) | null
}

const initialOnboardingState: OnboardingState = {
  contents: null,
  closeCallback: null
}

const OnboardingContext = createContext<OnboardingState>(initialOnboardingState)
const SetOnboardingContext = createContext<
  React.Dispatch<React.SetStateAction<OnboardingState>>
>(() => {})

export const OnboardingProvider = ({ children }: PropsWithChildren) => {
  const [onboarding, setOnboarding] = useState<OnboardingState>(
    initialOnboardingState
  )

  return (
    <OnboardingContext.Provider value={onboarding}>
      <SetOnboardingContext.Provider value={setOnboarding}>
        {children}
      </SetOnboardingContext.Provider>
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = (): OnboardingState => {
  const context = useContext(OnboardingContext)
  if (context === null) {
    throw new Error(
      'useOnboardingContext must be used within an OnboardingProvider'
    )
  }
  return context
}

export const useSetOnboardingContext = (): React.Dispatch<
  React.SetStateAction<OnboardingState>
> => {
  const context = useContext(SetOnboardingContext)
  if (context === null) {
    throw new Error(
      'useSetOnboardingContext must be used within an OnboardingProvider'
    )
  }
  return context
}
