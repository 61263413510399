import { Icon } from 'components/atoms/Icon'
import { useOnboardingContext } from 'contexts/OnboardingContext'

export const OnboardingMessage = () => {
  const { contents, closeCallback } = useOnboardingContext()

  if (contents === null) {
    return null
  }

  return (
    <div className="fixed bottom-4 right-4 z-[10000] flex w-[346px] max-w-[calc(100%-32px)] justify-center rounded bg-surface-normal p-4 pr-[54px] [box-shadow:var(--elevation-1)]">
      <button
        className="absolute right-2 top-2 z-[3] size-6 cursor-pointer appearance-none rounded-full border-0 bg-blackAlpha-200 p-1 text-text-invert"
        type="button"
        onClick={closeCallback ?? undefined}
      >
        <Icon type="close" size="small" color={'color-whiteAlpha-900'} />
      </button>
      {contents}
    </div>
  )
}
